import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { InputText } from "primereact/inputtext";
import AddModal from "./Modal/AddModal";
import EditModal from "./Modal/EditModal";
import SerialModal from "./Modal/SerialModal";
const php = new Php();

const height = window.innerHeight;

export default function Billsend() {
  const [billSend, setBillSend] = useState([]);
  const [billSendId, setBillSendId] = useState(0);
  const [billSendSerial, setBillSendSerial] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    load_bill_send();
  }, []);

  const load_bill_send = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
    };

    php.load_bill_send(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBillSend(r.bill_send);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const sendAllBill = () => {
    if (window.confirm("Do you really want to SEND ALL BILLS?")) {
      setLoading(true);
      let filteredData = billSend
        .filter((item) => item.serial < 99999)
        .sort((a, b) => a.serial - b.serial);
      console.log(filteredData);
      filteredData.forEach((item, index) => {
        setTimeout(() => {
          handleBillSend2(item);
          if (index === filteredData.length - 1) {
            setLoading(false);
          }
        }, 6000 * index); // 3000 milliseconds * index ensures each request is delayed by an additional 3 seconds
        console.log(index);
      });
    }
  };

  const sendAllGroup = () => {
    if (window.confirm("Do you really want to SEND ALL GROUP BILLS?")) {
      setLoading(true);
      let filteredData = billSend
        .filter((item) => item.serial < 99999)
        .sort((a, b) => a.serial - b.serial);

      filteredData.forEach((item, index) => {
        setTimeout(() => {
          handleGroupSend2(item);
          if (index === filteredData.length - 1) {
            setLoading(false);
          }
        }, 6000 * index); // 3000 milliseconds * index ensures each request is delayed by an additional 3 seconds
      });
    }
  };

  const handleGroupSend2 = (data) => {
    let url = "";

    switch (data.type) {
      case "brokerbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/broker_bill.php?username=${data.username}&server=${data.server}&ids=${data.id}&group=1`;
        break;
      case "masterbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/MasterBill.php?username=${data.username}&server=${data.server}&ids=${data.id}&group=1`;
        break;
      case "superbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/SuperBill.php?username=${data.username}&server=${data.server}&ids=${data.id}&group=1`;
        break;
      case "adminbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/AdminBill.php?username=${data.username}&server=${data.server}&ids=${data.id}&group=1`;
        break;
      case "brokersummary":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/BrokerSummary.php?username=${data.username}&server=${data.server}&ids=${data.id}&group=1`;
        break;
    }

    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // or response.json() if the server responds with JSON
        })
        .then((result) => console.log(`Response from ${data.type}:`, result))
        .catch((error) => console.error(`Error fetching ${data.type}:`, error));
    }
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          gap: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-plus"
          severity="warning"
          label={!loading ? "Send All Group Bill" : "Loading"}
          style={{
            width: 120,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={!loading ? sendAllGroup : undefined}
          data-pr-tooltip="PDF"
        />
        <Button
          type="button"
          // icon="pi pi-plus"
          severity="warning"
          label={!loading ? "Send All Bill" : "Loading"}
          style={{
            width: 120,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={!loading ? sendAllBill : undefined}
          data-pr-tooltip="PDF"
        />
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
        <Button
          type="button"
          icon="pi pi-plus"
          severity="warning"
          label={"Add"}
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={() => show()}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  const handleBillSend2 = (data) => {
    let url = "";

    switch (data.type) {
      case "brokerbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/broker_bill.php?username=${data.username}&server=${data.server}&ids=${data.id}`;
        break;
      case "masterbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/MasterBill.php?username=${data.username}&server=${data.server}&ids=${data.id}`;
        break;
      case "superbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/SuperBill.php?username=${data.username}&server=${data.server}&ids=${data.id}`;
        break;
      case "adminbill":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/AdminBill.php?username=${data.username}&server=${data.server}&ids=${data.id}`;
        break;
      case "brokersummary":
        url = `https://testapi.dreamtrade.biz/ExtraPhpApis/BrokerSummary.php?username=${data.username}&server=${data.server}&ids=${data.id}`;
        break;
    }

    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // or response.json() if the server responds with JSON
        })
        .then((result) => console.log(`Response from ${data.type}:`, result))
        .catch((error) => console.error(`Error fetching ${data.type}:`, error));
    }
  };

  const handleBillSend = (data) => {
    if (data.type == "brokerbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/broker_bill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id,
        "_blank"
      );
    }
    if (data.type == "masterbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/MasterBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id,
        "_blank"
      );
    }

    if (data.type == "superbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/SuperBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id,
        "_blank"
      );
    }

    if (data.type == "adminbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/AdminBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id,
        "_blank"
      );
    }
    if (data.type == "brokersummary") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/BrokerSummary.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id,
        "_blank"
      );
    }
  };

  const handleGroupEntry = (data) => {
    if (data.type == "brokerbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/broker_bill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id +
          "&group=1",
        "_blank"
      );
    }
    if (data.type == "masterbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/MasterBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id +
          "&group=1",
        "_blank"
      );
    }

    if (data.type == "superbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/SuperBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id +
          "&group=1",
        "_blank"
      );
    }

    if (data.type == "adminbill") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/AdminBill.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id +
          "&group=1",
        "_blank"
      );
    }
    if (data.type == "brokersummary") {
      window.open(
        "http://testapi.dreamtrade.biz/ExtraPhpApis/BrokerSummary.php?username=" +
          data.username +
          "&server=" +
          data.server +
          "&ids=" +
          data.id +
          "&group=1",
        "_blank"
      );
    }
  };

  const deleteEntry = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: id,
    };

    php.delete_bill_send_entry(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_bill_send();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const handleBrokerageReturn = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: id,
    };

    php.bill_send_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_bill_send();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const [visible, setVisible] = useState(false);
  const [visibleSerial, setVisibleSerial] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showSerial = (position_id) => {
    setBillSendSerial(position_id);
    setVisibleSerial(true);
  };

  const showEdit = (position_id) => {
    setBillSendId(position_id);
    setVisibleEdit(true);
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Bill Send" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={billSend}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          globalFilterFields={[
            "username",
            "server",
            "wa1",
            "wa2",
            "wa3",
            "bhaag",
            "type",
          ]}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            sortable
            field="serial"
            showFilterMenu={false}
            header="Serial"
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showSerial(rowData)}
                />
                <span>{rowData.serial}</span>
              </div>
            )}
          />
          <Column
            field="group_array"
            sortable
            showFilterMenu={false}
            header="Group"
            body={(rowData) => <div>{rowData?.group_array}</div>}
          />
          <Column
            field="type"
            sortable
            showFilterMenu={false}
            header="Bill Type"
            body={(rowData) => <div>{rowData?.type?.toUpperCase()}</div>}
          />
          <Column
            field="username"
            sortable
            showFilterMenu={false}
            header="Username"
          />
          <Column
            field="server"
            sortable
            showFilterMenu={false}
            header="Server"
          />
          <Column
            field="bhaag"
            sortable
            showFilterMenu={false}
            header="Bhaag"
          />

          <Column field="wa1" showFilterMenu={false} header="WA 1" />
          <Column field="wa2" showFilterMenu={false} header="WA 2" />
          <Column field="wa3" showFilterMenu={false} header="WA 3" />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Brokerage Return"
            body={(rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  label={rowData.brokreturn == 0 ? "No" : "Yes"}
                  severity={rowData.brokreturn == 0 ? "danger" : "success"}
                  style={{ height: 25, fontSize: 12 }}
                  onClick={() => handleBrokerageReturn(rowData.id)}
                />
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-verified"
                  severity="Help"
                  style={{ height: 25, width: 25 }}
                  onClick={() => handleGroupEntry(rowData)}
                />
                <Button
                  type="button"
                  icon="pi pi-send"
                  severity="warning"
                  style={{ height: 25, width: 25 }}
                  onClick={() => handleBillSend(rowData)}
                />
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData.id)}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ height: 25, width: 25 }}
                  onClick={() => deleteEntry(rowData.id)}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <AddModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          reload={() => load_bill_send()}
          visible={visible}
        />
      )}
      {visibleEdit && (
        <EditModal
          key={new Date()}
          setVisible={() => {
            setVisibleEdit(false);
            load_bill_send();
          }}
          position={position}
          visible={visibleEdit}
          billSendId={billSendId}
          reload={() => load_bill_send()}
        />
      )}
      {visibleSerial && (
        <SerialModal
          key={new Date()}
          setVisible={() => {
            setVisibleSerial(false);
            load_bill_send();
          }}
          position={position}
          visible={visibleSerial}
          billSendSerial={billSendSerial}
          reload={() => load_bill_send()}
        />
      )}
    </div>
  );
}
