import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NewTheme } from "../../Theme/Theme";
import { useNotification } from "../../Notification/NotificationContext";
import EditEntry from "./EditEntry";

const php = new Php();

const height = window.innerHeight;

export default function EntryModal(props) {
  const { addNotification } = useNotification();
  const [groupArray, setGroupArray] = React.useState([]);

  const [sendId, setSendId] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const showEdit = (position_id) => {
    setSendId(position_id);
    setVisibleEdit(true);
  };

  React.useEffect(() => {
    load_group_entry();
  }, []);

  const load_group_entry = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: props.billSendId,
    };

    php.load_group_entry(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setGroupArray(r.bill_send);
      } else {
        window.alert(r.message);
      }
    });
  };

  const deleteGroupEntry = (id) => {
    if (window.confirm("Do you really want to delete?")) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        e_id: id,
      };

      php.delete_group_entry(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          props.reload();
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <>
      <Dialog
        header={
          <div style={{ color: NewTheme.MainColor }}>
            {" Group Entry : " + props.groupName}
          </div>
        }
        visible={props.visible}
        position={props.position}
        style={{ width: "70vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => props.setVisible(false)}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 120}
          size="small"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={!loading && groupArray}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={["username", "symbol"]}
          paginator
          rows={15}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            field="server"
            showFilterMenu={false}
            header="Server"
            sortable
          />
          <Column
            field="client"
            showFilterMenu={false}
            header="Username"
            sortable
          />
          <Column
            field="amount"
            showFilterMenu={false}
            header="Amount"
            sortable
            body={(rowData) => <div>{rowData.amount}</div>}
          />
          <Column
            field="remarks"
            showFilterMenu={false}
            header="Remarks"
            sortable
            body={(rowData) => <div>{rowData.remarks}</div>}
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData.id)}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ height: 25, width: 25 }}
                  onClick={() => deleteGroupEntry(rowData.id)}
                />
              </div>
            )}
          />
        </DataTable>
      </Dialog>
      {visibleEdit && (
        <EditEntry
          key={new Date()}
          setVisible={() => {
            setVisibleEdit(false);
          }}
          position={position}
          visible={visibleEdit}
          sendId={sendId}
          reload={() => {
            load_group_entry();
            props.reload();
          }}
        />
      )}
    </>
  );
}
