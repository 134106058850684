import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { InputText } from "primereact/inputtext";
import AddModal from "./Modal/AddModal";
import EditModal from "./Modal/EditModal";
import SerialModal from "./Modal/SerialModal";
const php = new Php();

const height = window.innerHeight;

export default function SheetMaster() {
  const [billSend, setBillSend] = useState([]);
  const [billSendId, setBillSendId] = useState(0);
  const [billSendSerial, setBillSendSerial] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    load_sheet_master();
  }, []);

  const load_sheet_master = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
    };

    php.load_sheet_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBillSend(r.sheets_master);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          gap: 10,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
        <Button
          type="button"
          icon="pi pi-plus"
          severity="warning"
          label={"Add"}
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={() => show()}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  const deleteEntry = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: id,
    };

    php.delete_sheet_master_entry(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_sheet_master();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const handleBrokerageReturn = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: id,
    };

    php.sheet_master_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_sheet_master();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const [visible, setVisible] = useState(false);
  const [visibleSerial, setVisibleSerial] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showSerial = (position_id) => {
    setBillSendSerial(position_id);
    setVisibleSerial(true);
  };

  const showEdit = (position_id) => {
    setBillSendId(position_id);
    setVisibleEdit(true);
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Sheet Master" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={billSend}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          globalFilterFields={["username", "server", "bhaag", "type"]}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="type"
            sortable
            showFilterMenu={false}
            header="Sheet Type"
            body={(rowData) => <div>{rowData?.type?.toUpperCase()}</div>}
          />
          <Column
            field="sheet_name"
            sortable
            showFilterMenu={false}
            header="Sheet Name"
            body={(rowData) => <div>{rowData?.sheet_name?.toUpperCase()}</div>}
          />
          <Column
            field="username"
            sortable
            showFilterMenu={false}
            header="Username"
          />
          <Column
            field="server"
            sortable
            showFilterMenu={false}
            header="Server"
          />

          <Column
            field="sharing"
            sortable
            showFilterMenu={false}
            header="Bhaag"
          />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="refund"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Brokerage Refund"
            body={(rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  label={rowData.refund == 0 ? "No" : "Yes"}
                  severity={rowData.refund == 0 ? "danger" : "success"}
                  style={{ height: 25, fontSize: 12 }}
                  onClick={() => handleBrokerageReturn(rowData.id)}
                />
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData.id)}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ height: 25, width: 25 }}
                  onClick={() => deleteEntry(rowData.id)}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <AddModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          reload={() => load_sheet_master()}
          visible={visible}
        />
      )}
      {visibleEdit && (
        <EditModal
          key={new Date()}
          setVisible={() => {
            setVisibleEdit(false);
            load_sheet_master();
          }}
          position={position}
          visible={visibleEdit}
          billSendId={billSendId}
          reload={() => load_sheet_master()}
        />
      )}
      {visibleSerial && (
        <SerialModal
          key={new Date()}
          setVisible={() => {
            setVisibleSerial(false);
            load_sheet_master();
          }}
          position={position}
          visible={visibleSerial}
          billSendSerial={billSendSerial}
          reload={() => load_sheet_master()}
        />
      )}
    </div>
  );
}
