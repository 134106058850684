import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../../Theme/Theme";
import {
  BalanceContainer,
  BalanceInput,
  BalanceLabel,
  BalanceWrapper,
} from "../../Users/Add/AddUser";

const php = new Php();

export default function EditEntry(props) {
  const [amount, setAmount] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const editGroupEntry = (e) => {
    if (amount == "") {
      window.alert("Enter Amount");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          e_id: props.sendId,
          amount: amount,
        };

        php.edit_group_entry(data).then((r) => {
          if (r.error === "False") {
            props.setVisible(false);
            window.alert(r.message);
            props.reload();
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />

      <Button
        label="Update"
        icon="pi pi-pencil"
        onClick={() => editGroupEntry()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Edit Group Entry"}</div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        {!loading && (
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Amount </BalanceLabel>
              <BalanceInput
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                placeholder="Enter Amount "
              />
            </BalanceContainer>
          </BalanceWrapper>
        )}
      </>
    </Dialog>
  );
}
