import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../../Theme/Theme";
import {
  BalanceContainer,
  BalanceInput,
  BalanceLabel,
  BalanceWrapper,
} from "../../Users/Add/AddUser";

const php = new Php();

export default function AddModal(props) {
  const [groupArray, setGroupArray] = React.useState([]);
  const [selectedGroupArray, setSelectedGroupArray] = React.useState({});
  const [server, setServer] = React.useState("");
  const [username, setUsername] = React.useState("");

  const [bhaag, setBhaag] = React.useState("unset");
  const [selectedType, setSelectedType] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const type = [
    { id: "MASTER", name: "Master" },
    { id: "SUPER", name: "Super" },
    { id: "ADMIN", name: "Admin" },
  ];

  const addBillSendEntry = (e) => {
    if (username == "") {
      window.alert("Enter Username");
    } else if (server == "") {
      window.alert("Enter Server");
    } else if (selectedType?.id == "" || selectedType?.id == undefined) {
      window.alert("Select Bill Type");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          type: selectedType?.id,
          bhaag: bhaag,
          username: username,
          server: server,
        };

        php.add_sheet_master_entry(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            window.alert(r.message);
            // props.setVisible(false);
            // props.reload();
          } else {
            window.alert(r.message);
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />

      <Button
        label="Add"
        icon="pi pi-plus"
        onClick={() => addBillSendEntry()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Add Sheet Master Entry"}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <BalanceWrapper>
          <BalanceContainer>
            <BalanceLabel> Server </BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setServer(e.target.value)}
              value={server}
              placeholder="Enter Server "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel>Bill Type </BalanceLabel>
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "50%",
                height: 30,
              }}
              showClear
              onChange={(e) => {
                console.log(e);
                if (e.value == undefined) {
                  setSelectedType({});
                } else {
                  setSelectedType(e.value);
                }
              }}
              value={selectedType}
              options={type}
              optionLabel="name"
              placeholder="Select Type"
              filter
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel> Username </BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Enter Username "
            />
          </BalanceContainer>

          <BalanceContainer>
            <BalanceLabel>Bhaag</BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setBhaag(e.target.value)}
              value={bhaag}
              placeholder="Enter Bhaag "
            />
          </BalanceContainer>
        </BalanceWrapper>
      </>
    </Dialog>
  );
}
