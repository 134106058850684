import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";

import Notification from "./Screens/Notification/Notification";
import { NotificationProvider } from "./Screens/Notification/NotificationContext";

// Import your components for different routes
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { io } from "socket.io-client";
import Brokerage from "./Screens/Brokerage/Brokerage";
import Brokers from "./Screens/Brokers/Brokers";
import Holding from "./Screens/Holding/Holding";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginProvider from "./Screens/Login/LoginContext";
import Masters from "./Screens/Master/Masters";
import Pending from "./Screens/Pending/Pending";
import Position from "./Screens/Position/Position";
import MySidebar from "./Screens/Sidebar/MySidebar";
import { SidebarProvider } from "./Screens/Sidebar/SidebarContext";
import Transaction from "./Screens/Transaction/Transaction";
import Users from "./Screens/Users/Users";
import Watchlist from "./Screens/Watchlist/Watchlist";
import SocketContect from "./SocketContext/SocketContext";
import Supers from "./Screens/Super/Supers";
import Admins from "./Screens/Admin/Admins";
import Profile from "./Screens/Profile/Profile";
import Billsend from "./Screens/BillSend/BillSend";
import Scripts from "./Screens/Scripts/Scripts";
import AdminRules from "./Screens/Extra/AdminRules";
import AdminNotification from "./Screens/Extra/AdminNotification";
import Closing from "./Screens/Closing/Closing";
import IpReport from "./Screens/Ip/IpReport";
import DeactiveAccountReport from "./Screens/DeactiveAccount/DeactiveAccount";
import GroupBill from "./Screens/GroupBill/GroupBill";
import SheetMaster from "./Screens/SheetMaster/SheetMaster";

var socket = io("https://emit.dreamtrade.biz");

const menuItems = [
  { name: "Home", component: Home },
  { name: "Admins", component: Admins },
  { name: "Supers", component: Supers },
  { name: "Masters", component: Masters },
  { name: "Brokers", component: Brokers },
  { name: "Users", component: Users },
  { name: "Positions", component: Position },
  { name: "Transactions", component: Transaction },
  { name: "Pendings", component: Pending },
  { name: "Watchlist", component: Watchlist },
  { name: "Brokerage", component: Brokerage },
  { name: "Holdings", component: Holding },
  { name: "Profile", component: Profile },
  { name: "Billsend", component: Billsend },
  { name: "Scripts", component: Scripts },
  { name: "Rules", component: AdminRules },
  { name: "Notifications", component: AdminNotification },
  { name: "Closing", component: Closing },
  { name: "IpReport", component: IpReport },
  { name: "DeactiveAccountReport", component: DeactiveAccountReport },
  { name: "GroupBill", component: GroupBill },
  { name: "SheetMaster", component: SheetMaster },
  // Add more routes for logged-in users as needed
];

function App() {
  socket.on("connect", () => {
    if (localStorage.getItem("isLoggedGod") == "1") {
      var get_data = JSON.parse(localStorage.getItem("godsDetails"));

      socket.emit("login", {
        type: "God",
        id: localStorage.getItem("godsId"),
      });
    }
  });

  // localStorage.clear();
  const isLogged = localStorage.getItem("isLoggedGod") === "1";

  return (
    <div className="App">
      <SocketContect.Provider value={socket}>
        <SidebarProvider>
          <NotificationProvider>
            <LoginProvider>
              <PrimeReactProvider>
                <Router>
                  {isLogged && <MySidebar />}

                  <Routes>
                    {isLogged ? (
                      menuItems.map((menuItem) => (
                        <Route
                          key={menuItem.name}
                          path={`/${menuItem.name.toLowerCase()}`}
                          element={<menuItem.component />}
                        />
                      ))
                    ) : (
                      <>
                        <Route path="login" element={<Login />} />
                        <Route path="Login" element={<Login />} />
                      </>
                    )}
                    <Route
                      path="*"
                      element={<Navigate to={isLogged ? "/home" : "/login"} />}
                    />
                  </Routes>
                </Router>
                <Notification />
              </PrimeReactProvider>
            </LoginProvider>
          </NotificationProvider>
        </SidebarProvider>
      </SocketContect.Provider>
    </div>
  );
}

export default App;
